import { Box, CircularProgress, Container, Grid, Table, TableContainer } from "@mui/material";
import { useAuth } from "AuthProvider";
import axios from "axios";
import MKTypography from "components/MKTypography";
import { config } from "constants/config/config";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Requests({ children, value, index, ...other }) {
    const { t } = useTranslation();
    console.log(children)
    const { user, token } = useAuth();
    const [requests, setRequests] = useState({});
    const [loading, setLoading] = useState(false);

    const status = {
        "PENDING": t("Declaration.Request.PENDING"),
        "COMPLETED": t("Declaration.Request.COMPLETED"),
        "REJECTED": t("Declaration.Request.REJECTED"),
    }
    
    const scopes = {
        "ENTERPRISES": t("FNTEC.enterprises"),
        "EMPLOYEES": t("FNTEC.employes"),
        "CONTRACTS": t("FNTEC.contracts"),
    }

    const types = {
        "DELETE": t("Declaration.Request.DELETE"),
        "UPDATE": t("Declaration.Request.UPDATE"),
        "CREATE": t("Declaration.Request.CREATE"),
    }

    useEffect(() => {
        if (user) {
            showRequests(user);
        }
    }, [user]);

    //FETCH EMPLOYEES BY ENTERPRISE ID
    const showRequests = (user) => {
        if (!user) return
        setLoading(true);
        setRequests([]);
        axios
            .get(`${config.app.api_url}/enterprises/${user?.myEnterprise?.id}/requests`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            .then((res) => {
                console.log("REQUESTS ", res);
                setRequests(res?.data?.data);
            })
            .catch((err) => {
                console.log("REQUESTS ERR ", err);
                setRequests([]);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <ToastContainer />
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Container>
                        <Grid
                            container
                            item
                            xs={7}
                            lg={12}
                            md={7}
                            justifyContent="space-between" // Gérer l'espacement entre le texte et le bouton
                            alignItems="flex-end" // Aligner le bouton au fond (bas) à droite
                        >
                            <MKTypography
                                variant="h3"
                                color="black"
                                
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    '::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '50%',
                                        height: '3px',
                                        backgroundColor: '#FCE410',
                                        transform: 'translateX(-200%)',
                                    },
                                }}
                                mr={5}
                                mb={3}
                            >
                                {t("Declaration.Request.list")} ({!loading && requests ? requests?.ENTERPRISE?.content.length + requests?.EMPLOYEE?.content.length+ requests?.CONTRACT?.content.length : 0})
                            </MKTypography>
                        </Grid>

                    </Container>
                    <TableContainer sx={{mb : 3}}>
                        <h6>{scopes.ENTERPRISES}</h6>
                        <Table sx={{ mb: 3 }} responsive striped bordered hover size="sm" variant="light" className="custom-table">
                            <thead>
                                <tr style={{ fontSize: "14px" }}>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>#</th>
                                     <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("FNTEC.table.enterprise.raison")}</th>

                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.motif")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.type")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.statut")}</th>
                                </tr>
                            </thead>
                            {loading && <CircularProgress />}
                            {!loading && <tbody>
                                {requests?.ENTERPRISE && requests?.ENTERPRISE?.content.map((item, index) => (
                                    <tr style={{
                                        color: "#0F056B",
                                        verticalAlign: "bottom",
                                        fontSize: "12px"
                                    }} key={index}>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{index + 1}</td>
                                        <td style={{ color: "#0F056B" }}>{item?.enterprise?.socialRaison }</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{item?.motif}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{types[item?.requestType]}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{status[item?.status]}</td>
                                    </tr>
                                ))}
                            </tbody>}
                        
                        </Table>
                        <h6>{scopes.EMPLOYEES}</h6>
                        <Table sx={{ mb: 3 }} responsive striped bordered hover size="sm" variant="light" className="custom-table">
                            <thead>
                                <tr style={{ fontSize: "14px" }}>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>#</th>
                                                                        <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.noms")}</th>
                                   
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.motif")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.type")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.statut")}</th>
                                </tr>
                            </thead>
                            {loading && <CircularProgress />}
                         
                            {!loading && <tbody>
                                {requests?.EMPLOYEE && requests?.EMPLOYEE?.content.map((item, index) => (
                                    <tr style={{
                                        color: "#0F056B",
                                        verticalAlign: "bottom",
                                        fontSize: "12px"
                                    }} key={index}>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{index + 1}</td>
                                        <td style={{ color: "#0F056B" }}>{item?.employer?.fullName || item?.employer?.firstName +" "+item?.employer?.lastName}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{item?.motif}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{types[item?.requestType]}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{status[item?.status]}</td>
                                    </tr>
                                ))}
                            </tbody>}
                          
                        </Table>
                        <h6>{scopes.CONTRACTS}</h6>
                        <Table sx={{ mb: 3 }} responsive striped bordered hover size="sm" variant="light" className="custom-table">
                            <thead>
                                <tr style={{ fontSize: "14px" }}>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>#</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.noms")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.motif")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.type")}</th>
                                    <th className="text-center" style={{
                                        color: "#0F056B",
                                        textTransform: "uppercase"
                                    }}>{t("Declaration.Request.table.statut")}</th>
                                </tr>
                            </thead>
                            {loading && <CircularProgress />}
                            
                            {!loading && <tbody>
                                {requests?.CONTRACT && requests?.CONTRACT?.content.map((item, index) => (
                                    <tr style={{
                                        color: "#0F056B",
                                        verticalAlign: "bottom",
                                        fontSize: "12px"
                                    }} key={index}>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{index + 1}</td>
                                        <td style={{ color: "#0F056B" }}>{item?.employer?.fullName || item?.employer?.firstName +" "+item?.employer?.lastName}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{item?.motif}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{types[item?.requestType]}</td>
                                        <td className="text-center" style={{ color: "#0F056B" }}>{status[item?.status]}</td>
                                    </tr>
                                ))}
                            </tbody>}
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </div>
    );
}

// Ajouter la validation des props
Requests.propTypes = {
    children: PropTypes.string.isRequired, // 'text' doit être une chaîne de caractères
    value: PropTypes.any.isRequired,   // 'value' peut être de n'importe quel type
    index: PropTypes.number.isRequired // 'index' doit être un nombre
};

export default Requests;