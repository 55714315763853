import { Card, CardContent, Container, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "routes";
// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import minefop from "assets/images/minefop.jpg";
import { useEffect, useState } from "react";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "AuthProvider";
import usePost from "hooks/usePost";
import { config } from "constants/config/config";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function LoginMinefop() {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorData, setErrorData] = useState("");
    const [errors, setErrors] = useState({});
    const { login } = useAuth();
    const [postData, load, error, data] = usePost(`${config.app.api_url}/auth/login`);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!email) newErrors.email = t("Register.errors.email");
        if (!password) newErrors.password = t("Register.errors.password");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            await postData({ email, password });
        }
    };

    useEffect(() => {
        console.log("DATA ", data)
        if (data && data?.user) {
            if (data && data.access_token) {
            // Si la requête a réussi et que nous avons un token, appeler login
            login(data.user, data.access_token);
            navigate("/")
                }
            } else if (data && !data?.isEmailVerified) {
                navigate("/account/verification", { state: { data, mail: email } });
        } else {
            setErrorData(data?.message)
        }
    }, [data])

    return (
        <MKBox sx={{ background: "#fff" }}>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left", mt: 10 }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Login.title")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Container>
                <Grid className="d-flex justify-content-center">
                    <img
                        className="d-block w-20"
                        src={minefop}
                        style={{ height: '30vh' }}
                    />
                </Grid>
                <Grid className="d-flex justify-content-center">
                    <Card style={{ background: "rgba(8, 56, 4, 0.72)", width: "150vh" }}>
                        <CardContent style={{ textAlign: "center" }}>
                            <MKTypography
                                variant="h5"
                                color="white"
                            >
                                {t("Login.card.title")}
                            </MKTypography>
                            <MKTypography
                                variant="h5"
                                color="white"
                            >
                                {t("***********")}
                            </MKTypography>
                            <MKTypography
                                variant="h5"
                                color="white"
                            >
                                {t("MINEFOP")}
                            </MKTypography>
                            {error && <MKTypography
                                variant="subtitle2"
                                color="white"
                                style={{ background: "red", mt: 2 }}
                            >
                                {error}
                            </MKTypography>}
                            {errorData && <MKTypography
                                variant="subtitle2"
                                color="white"
                                style={{ background: "red", mt: 2 }}
                            >
                                {errorData}
                            </MKTypography>}
                            <Container>
                                <form onSubmit={handleSubmit}>
                                    <MKBox>
                                        <Grid container justifyContent={"center"} mt={3} alignItems={"center"}>
                                            <MKTypography
                                                mt={0}
                                                variant="subtitle2"
                                                color="white"
                                            >
                                                {t("Login.card.already")}
                                            </MKTypography>
                                            <MKButton
                                                variant="contained"
                                                color="white"
                                                type="submit"
                                                size="sm"
                                                component="a"
                                                onClick={() => navigate("/register")}
                                                sx={{ ml: 2, alignSelf: 'center', height: 'fit-content' }}
                                            >
                                                {t("Register.title")} {t("here")}
                                            </MKButton>
                                        </Grid>
                                        <TextField
                                            required
                                            sx={{
                                                mt: 5,
                                                width: "100%",
                                                "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            placeholder="example@example.com"
                                            id="outlined-required"
                                            type="email"
                                            label={t("Login.card.email")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                        <TextField
                                            required
                                            sx={{
                                                mt: 2,
                                                width: "100%",
                                                "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#fff",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#fff", // Couleur de la bordure en mode focus
                                                    },
                                                },
                                                "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                "& .MuiOutlinedInput-input::placeholder": {
                                                    color: "#fff", // Couleur du placeholder
                                                    opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                },
                                            }}
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            placeholder="*********************"
                                            id="outlined-required"
                                            type={showPassword ? 'text' : 'password'}
                                            label={t("Login.card.password")}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.password}
                                            helperText={errors.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={showPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <MKBox>
                                            <Link style={{ textDecoration: 'none' }} to="/login">
                                                <MKTypography
                                                    mt={3}
                                                    variant="subtitle2"
                                                    color="white"
                                                    sx={{ textAlign: 'left' }}
                                                >
                                                    {t("Login.card.forget")}
                                                </MKTypography>
                                            </Link>
                                        </MKBox>
                                        <MKButton
                                            disabled={load}
                                            variant="contained"
                                            color="white"
                                            size="large"
                                            component="a"
                                            onClick={handleSubmit}
                                            sx={{ mt: 5, alignSelf: 'center', height: 'fit-content' }}
                                        >
                                            {load ? t("Login.card.loading") : t("Login.card.connexion")}
                                        </MKButton>
                                    </MKBox>
                                </form>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </MKBox>
    )
}

export default LoginMinefop;